@import "./../components/sidebar/scss/variables.scss";

$sidebar-height: 60vh !default;

.mapSidebar-container {
  position: fixed;
  height: $sidebar-height;
  right: 0px;
  top: 50%;
  z-index: 99999;
  margin-top: -$sidebar-height/2;
}
