$sidebar-bg-color: #eeeeee !default;
$sidebar-color: black !default;
$sidebar-width: 270px !default;
$sidebar-collapsed-width: 80px !default;
$highlight-color: var(--amplify-primary-shade) !default;
$submenu-bg-color: #dddddd5a !default;
//$submenu-bg-color: #eeeeee !default;
$submenu-bg-color-collapsed: #dadadaec !default;
$icon-bg-color: #ffffff !default;
$icon-size: 35px !default;

$breakpoint-xs: 480px !default;
$breakpoint-sm: 576px !default;
$breakpoint-md: 768px !default;
$breakpoint-lg: 992px !default;
$breakpoint-xl: 1200px !default;
