@import "./../../components/sidebar/scss/variables.scss";
@import "./../MapEditor.scss";

.sidebar-collapse-toggle {
  position: fixed;
  right: $sidebar-width - 50px;
  top: 50%;
  margin-top: $sidebar-height/2;
}

.collapse-arrow-wrapper {
  position: fixed;
  right: 20px;
  top: 50vh + $sidebar-height/2;
  z-index: 1100;
  margin-top: -25px;
  transform: translateY(-50%);
  cursor: pointer;

  .collapse-arrow-clicked {
    display: inline-block;
    vertical-align: middle;
    border-style: solid;
    border-color: var(--amplify-primary-color);
    transition: transform 1s, margin-right 1s;
    border-width: 0 6px 6px 0;
    padding: 8px;
    transform: rotate(135deg);
    margin-right: 4px;
  }

  .collapse-arrow {
    display: inline-block;
    border-style: solid;
    border-color: var(--amplify-primary-color);
    border-width: 0 4px 4px 0;
    padding: 6px;
    vertical-align: middle;
    transition: transform 1s, margin-right 1s;
    transform: rotate(-45deg);
    margin-right: 0px;
  }
}

.text-icon {
  color: var(--amplify-primary-shade);
  font-size: 1.5em;
  font-weight: 650;
}

.add-feature {
  color: gray;
  cursor: pointer;
  width: 100%;
  display: inline-block;
  font-weight: 300;
  padding: 15px 0px 15px 15px;
}

.add-feature.collapsed {
  padding-left: 5px;
}

.add-feature:hover {
  font-weight: 450;
}

.add-feature:focus {
  outline: none;
  font-weight: 450;
}

.badge {
  padding: 2px 6px;
  font-size: 0.8em;
  font-weight: 00;
  border-radius: 15px;
  border: 1px solid black;
  &.red {
    color: #d63030;
    border-color: #d63030;
  }
  &.gray {
    color: rgb(114, 114, 114);
    border-color: rgb(114, 114, 114);
  }
  &.black {
    color: black;
    border-color: black;
  }
  &.yellow {
    color: #ffd331;
    border-color: #ffd331;
  }
  &.gray-background {
    color: #ffffff;
    background: rgb(114, 114, 114);
  }
  &.yellow-background {
    color: #000000;
    background: #ffdf6c;
    border-width: 0px;
  }
}

.reorder-container {
  width: 100%;
  background-color: pink;
  display: flex;

  .move-up {
    background-color: blue;
    flex-grow: 1;
  }

  .move-down {
    flex-grow: 1;
  }
}

.delete-tee {
  padding: 7px;
}

.add-new-tee {
  color: gray;
}
