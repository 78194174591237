body {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

.sans-serif,
button {
  font-family: -apple-system, BlinkMacSystemFont, avenir next, avenir,
    helvetica neue, helvetica, Ubuntu, roboto, noto, segoe ui, arial, sans-serif;
}

.disappear-child .child {
  display: none;
}
.disappear-child:hover .child,
.disappear-child:focus .child,
.disappear-child:active .child {
  display: block;
}

.CodeMirror {
  font-family: Menlo, Consolas, Monaco, Liberation Mono, Lucida Console,
    monospace;
  font-size: 14px;
  line-height: 20px;
  height: auto;
  flex: 1 1 auto;
}

.error-marker {
  width: 10px;
  height: 1.5em;
  background: var(--red);
  transition: all 100ms;
  -webkit-transition: all 100ms;
}

.error-marker:hover {
  background: #d68;
}

.error-marker:before {
  opacity: 0;
  pointer-events: none;
  content: attr(message);
  transition: all 100ms;
  -webkit-transition: all 100ms;
  content: attr(message);
  width: 300px;
  padding: 5px;
  border: 1px solid #000;
  position: absolute;
  background: #fff;
  z-index: 999;
}

.error-marker:hover:before {
  opacity: 1;
  margin-left: 35px;
}

div#leaflet-container .leaflet-bar a {
}

.golf-mark a {
  font-size: 0px;
}

.leaflet-bar a {
  color: #39c4ff;
}
.golf-mark a img {
  float: left;
}

.golf-mark-clicked {
  background-image: linear-gradient(
    to right,
    var(--blue),
    var(--amplify-primary-tint)
  );
  border-radius: 4px solid blue;
  color: white;
}

.golf-mark-clicked .tee-control {
  color: white;
}

.golf-mark-clicked .control-internal-control-container {
  display: flex;
}
.control-reorder-control:hover {
  color: var(--amplify-primary-color);
}

.disabled-control-overlay {
  width: 100%;
  height: 50px;
  position: absolute;
  cursor: default;
  top: 0;
  z-index: -1;
}

.control-disabled {
  z-index: 3;
}

.control-disabled {
  z-index: 3;
}

.control-disabled ~ div img {
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
}

.golf-mark a:hover {
  color: #fff;
  font-size: 14px;
  width: 200px;
  transition: width 0.1s, font-size 0.2s;
  transition-delay: 0.05s;
  transition-timing-function: linear;
}

div#leaflet-container .leaflet-bar .control-internal-container:hover {
  background-image: linear-gradient(
    to right,
    var(--blue),
    var(--amplify-primary-color)
  );
  color: #fff;
}

.control-img-icon {
  width: 40px;
  display: inline-block;
}

@media only screen and (max-width: 600px) {
  .control-img-icon {
    width: 30px;
  }
}

.control-internal-control-container {
  position: absolute;
  width: 60px;
  top: 4px;
  left: -60px;
  line-height: 20px;
  background-color: var(--light-gray);
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.65);
  border-radius: 4px;
  color: black;
  display: none;
}

div#leaflet-container .leaflet-bar .control-internal-control-container:hover {
  background-image: linear-gradient(to right, var(--grey), var(--white));
}

.control-disabled ~ div {
  color: black;
  background-image: none;
}

div#leaflet-container .leaflet-container {
  font-family: inherit;
}

.leaflet-popup-content-wrapper,
.leaflet-popup-tip {
  border: 1px solid #000;
}

.leaflet-popup-content-wrapper,
.leaflet-popup-tip {
  box-shadow: none;
  border-radius: 0;
  padding: 0;
}

.leaflet-popup-content {
  margin: 0;
}
amplify-sign-out .button {
  padding: 0px;
}

amplify-input .input {
  color: pink;
}

#username {
  background-color: pink;
  color: yellow;
}

.loader-container {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 9999;
  background-color: rgba(255, 255, 255, 0.7);
}

.loader-lds-dual-ring {
  display: inline-block;
  width: 80px;
  height: 80px;
}
.loader-lds-dual-ring:after {
  content: " ";
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid var(--amplify-primary-color);
  border-color: var(--amplify-primary-color) transparent
    var(--amplify-primary-shade) transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
