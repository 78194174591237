.button {
  margin: 10px;
  padding-left: 30px;
  padding-right: 30px;
  color: white;
  border-radius: 5px;
  outline: none;
}

.sign-out {
  background-color: var(--amplify-light-grey);
}

.button.publish {
  margin: 10px;
  padding-left: 30px;
  padding-right: 30px;
  color: white;
  border-radius: 5px;
  background-color: var(--amplify-primary-color);
}

.button.publish:hover {
  background-color: var(--amplify-primary-tint);
}

.button.sign-out:hover {
  background-color: var(--black-30);
}

.button.sign-out:active {
  background-color: var(--black-40);
}

.button.publish:active {
  background-color: var(--amplify-primary-shade);
}

.golfCourts-dropdown-title {
  color: var(--amplify-primary-color);
  line-height: 2.5;
}

* {
  font-weight: 400;
}

/* Burger menu */
.menu {
  min-height: 2.8rem; /* no way to control min height in Tachyons */
  max-height: 0; /* hide menu completely when burger unchecked */
  transition: max-height 0.5s; /* show/hide menu transition */
}
/* when checkbox is checked, display menu (sibling element) */
#burger:checked ~ .menu {
  max-height: 100%;
}
